<template>
  <div class="container">
    <h2>Nischinth Murari</h2>
    <p class="edu">
      BS Computer Science, <i> Appalachian State University </i> 2017- 2019
      <br />( OOP, Data Structures Algorithms, Calculus, Discrete Mathematics,
      Linear Algebra, X86 Assembly) <br /><br />
      MS Computer Science, <i> Appalachian State University </i> 2019 - 2021
      <br />( Vue, Swift, Python, Neural Nets, Machine Learning, Dynamic
      Programming, Algorithms)
    </p>

    <p class="desc">
      I am a Software Engineer with strong problem solving and technical skills.
      I enjoy pushing myself to the limit by always learning new technologies
      and refining preexisting skills. I have interests in Web, Cloud and Data
      Science.<br /><br />

      During my education at Appalachian State University, I was exposed to a
      wide variety of programming languages ranging from C and X86 Assembly all
      the way to cutting edge Javascript Frameworks like Vue.js and Node.js. I
      see specific technologies and stacks as means to an end. I am language
      agnostic and willing to learn new technologies, but my current stack is
      MEVN. <br /><br />
      I am a huge fan of Agile!
    </p>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.desc,
.edu {
  width: 500px;
  align-self: center;
}
</style>
